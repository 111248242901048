import Utilities from "./Utilities";

export default class Configuration {

  constructor(configObject) {

    window.CookieConsent.buffer = {
      appendChild: [],
      insertBefore: []
    }

    // Wrapper filter function
    window.CookieConsent.wrapper = function () { };

    // Settings injector for users
    window.CookieConsent.setConfiguration = this.setConfiguration.bind(this);

    window.CookieConsent.config = {
      active: true,
      cookieExists: false,
      cookieVersion: 1,
      modalMainTextMoreLink: null,
      showRejectAllButton: true,
      barTimeout: 1000,
      noUI: false,
      theme: {
        barColor: '#000000',
        barTextColor: '#FFF',
        barMainButtonColor: 'rgb(219, 137, 24)',
        barMainButtonTextColor: '#fff',
        modalMainButtonColor: '#4285F4',
        modalMainButtonTextColor: '#FFF',
      },
      language: {
        current: 'es',
        locale: {
          es: {
            barMaintitle: 'INFORMACIÓN BÁSICA SOBRE COOKIES',
            barMainText: 'Utilizamos cookies propias y de terceros para analizar nuestros servicios y mostrarle publicidad relacionada con sus preferencias, en base a un perfil elaborado a partir de sus hábitos de navegación (por ejemplo, páginas visitadas). Puede aceptar todas las cookies pulsando el botón “Aceptar todas las cookies”, configurarlas o permitir solo las necesarias. También puede obtener más información sobre el uso de cookies.',
            barLinkSetting: 'Configurar Cookies',
            barBtnAcceptNecesary: 'Usar solo cookies necesarias',
            barBtnAcceptSelect: 'Configurar selección',
            barBtnAcceptAll: 'Acceptar todas las cookies',
            barBtnRejectAll: 'Rechazar todas las cookies',
            modalMainTitle: 'Configurar Cookies',
            modalMainText: 'Las cookies son pequeños datos enviados desde un sitio web y almacenados en la computadora del usuario por el navegador web del usuario mientras el usuario está navegando. Su navegador almacena cada mensaje en un archivo pequeño, llamado cookie. Cuando solicita otra página del servidor, su navegador envía la cookie al servidor. Las cookies fueron diseñadas para ser un mecanismo confiable para que los sitios web recuerden información o registren la actividad de navegación del usuario.',
            modalBtnSave: 'Aceptar esta configuración',
            modalBtnAcceptAll: 'Aceptar todas las cookies',
            modalBtnRejectAll: 'Rechazar todas las cookies',
            modalAffectedSolutions: 'Proovedor de cookies:',
            learnMore: 'Learn More',
            on: 'On',
            off: 'Off',
            enabled: 'is enabled.',
            disabled: 'is disabled.',
            checked: 'checked',
            unchecked: 'unchecked',
          },
          en: {
            barMaintitle: 'BASIC INFORMATION ABOUT COOKIES',
            barMainText: 'We use our own and third-party cookies to analyze our services and show you advertising related to your preferences, based on a profile prepared from your browsing habits (for example, pages visited). You can accept all cookies by pressing the "Accept all cookies" button, configure them or allow only the necessary ones. You can also get more information about the use of cookies.',
            barLinkSetting: 'Cookie Settings',
            barBtnAcceptNecesary: 'Use only necessary cookies',
            barBtnAcceptSelect: 'Configure selection',
            barBtnAcceptAll: 'Accept all cookies',  
            barBtnRejectAll: 'Reject all cookies',
            modalMainTitle: 'Cookie settings',
            modalMainText: 'Cookies are small piece of data sent from a website and stored on the user\'s computer by the user\'s web browser while the user is browsing. Your browser stores each message in a small file, called cookie. When you request another page from the server, your browser sends the cookie back to the server. Cookies were designed to be a reliable mechanism for websites to remember information or to record the user\'s browsing activity.',
            modalBtnSave: 'Accept this configuration',
            modalBtnAcceptAll: 'Accept all cookies',
            modalBtnRejectAll: 'Reject all cookies',
            modalAffectedSolutions: 'Cookie provider:',
            learnMore: 'Learn More',
            on: 'On',
            off: 'Off',
            enabled: 'is enabled.',
            disabled: 'is disabled.',
            checked: 'checked',
            unchecked: 'unchecked',
          }
        }
      },
      categories: {
        necessary: {
          needed: true,
          wanted: true,
          checked: true,
          language: {
            locale: {
              en: {
                name: 'Cookies estrictamente necesarias',
                description: 'Las cookies necesarias ayudan a hacer una página web utilizable activando funciones básicas como la navegación en la página y el acceso a áreas seguras de la página web. La página web no puede funcionar adecuadamente sin estas cookies.',
              },
              es: {
                name: 'Cookies estrictamente necesarias',
                description: 'Las cookies necesarias ayudan a hacer una página web utilizable activando funciones básicas como la navegación en la página y el acceso a áreas seguras de la página web. La página web no puede funcionar adecuadamente sin estas cookies.',
              }
            }
          }
        },
        estadistica: {
          needed: false,
          wanted: false,
          checked: false,
          language: {
            locale: {
              en: {
                name: 'Estadística',
                description: 'Las cookies estadísticas ayudan a los propietarios de páginas web a comprender cómo interactúan los visitantes con las páginas web reuniendo y proporcionando información de forma anónima.',
              },
              es: {
                name: 'Estadística',
                description: 'Las cookies estadísticas ayudan a los propietarios de páginas web a comprender cómo interactúan los visitantes con las páginas web reuniendo y proporcionando información de forma anónima.',
              }
            }
          }
        },
        marketing: {
          needed: false,
          wanted: false,
          checked: false,
          language: {
            locale: {
              en: {
                name: 'Marketing',
                description: 'Las cookies de marketing se utilizan para rastrear a los visitantes en las páginas web. La intención es mostrar anuncios relevantes y atractivos para el usuario individual, y por lo tanto, más valiosos para los editores y terceros anunciantes.',
              },
              es: {
                name: 'Marketing',
                description: 'Las cookies de marketing se utilizan para rastrear a los visitantes en las páginas web. La intención es mostrar anuncios relevantes y atractivos para el usuario individual, y por lo tanto, más valiosos para los editores y terceros anunciantes.',
              }
            }
          }
        }
      }
    }

    this.setConfiguration(configObject);

  }

  setConfiguration(configObject) {
    // The user overrides the default config
    // console.log(window.CookieConsent.config, configObject, { ...window.CookieConsent.config, ...configObject });

    this.mergeDeep(window.CookieConsent.config, configObject)
    //loMerge(window.CookieConsent.config, configObject);
    // The cookie overrides the default and user config
    this.cookieToConfig();

    // We tell the world we did this
    Utilities.dispatchEvent(document, 'CCConfigSet');
  }

  cookieToConfig() {

    function removeReload() {
      Utilities.removeCookie();
      location.reload();
      return false;
    }

    document.cookie.split(';').filter((item) => {

      if (item.indexOf('cconsent') >= 0) {
        var cookieData = JSON.parse(item.split('=')[1]);

        // We check cookie version. If older we need to renew cookie.
        if (typeof cookieData.version === 'undefined') {
          return removeReload();
        } else {
          if (cookieData.version !== window.CookieConsent.config.cookieVersion) {
            return removeReload();
          }
        }

        // We check if cookie data categories also exist in user config
        for (let key in cookieData.categories) {

          // The cookie contains category not present in user config so we invalidate cookie
          if (typeof window.CookieConsent.config.categories[key] === 'undefined') {
            return removeReload();
          }
        }

        // We check if cookie data services also exist in user config
        cookieData.services.forEach(function (service) {

          // The cookie contains service not present in user config so we invalidate cookie
          if (typeof window.CookieConsent.config.services[service] === 'undefined') {
            return removeReload();
          }
        });

        // We we integrate cookie data into the global config object
        if (!window.CookieConsent.config.noUI) {
          for (let key in cookieData.categories) {
            window.CookieConsent.config.categories[key].checked = window.CookieConsent.config.categories[key].wanted = (cookieData.categories[key].wanted === true) ? true : false;
          }

          window.CookieConsent.config.cookieExists = true;
          return true;
        }
      }
    });

    return false;
  }


  // Simple object check.
  isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  //Deep merge two objects.
  mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }
}
