import { el, mount } from "redom";
import Language from "./Language";
import Utilities from "./Utilities";

export default class Interface {
  constructor() {
    this.elements = {};
  }

  buildStyle() {
    return el(
      "style",
      "html #cconsent-bar, html #cconsent-bar * { box-sizing:border-box; font-family: 'Gotham' }",
      "html #cconsent-bar { background-color:" +
        window.CookieConsent.config.theme.barColor +
        "; color:" +
        window.CookieConsent.config.theme.barTextColor +
        "; padding:15px; text-align:right; font-family: 'Gotham' sans-serif; font-size:14px; line-height:18px; position:fixed; bottom:0; left:0; width:100%; z-index:9998; transform: translateY(0); transition: transform .6s ease-in-out; transition-delay: .3s;}",
      "html #cconsent-bar.ccb--hidden {transform: translateY(100%); display:block;}",
      "html #cconsent-bar .ccb__wrapper { display:flex; flex-wrap:wrap; justify-content:space-between; max-width:1800px; margin:0 auto;}",
      "html #cconsent-bar .ccb__left { align-self:center; text-align:left; margin: 15px 0;}",
      "html #cconsent-bar .ccb__center { align-self:center; text-align:left; margin: 15px auto;max-width: 80%;}",
      "html #cconsent-bar .ccb__right { align-self:center; white-space: nowrap;}",
      "html #cconsent-bar .ccb__right > div {display:inline-block; color:#FFF;}",
      "html #cconsent-bar .cc-title {font-weight:bold; font-size:1.1em;}",
      "html #cconsent-bar a { text-decoration:underline; color:" +
        window.CookieConsent.config.theme.barTextColor +
        "; }",

      "html #cconsent-bar button.btn { line-height:normal; white-space: nowrap;width: auto;margin:10px 12px 10px 0px;border-radius: 2px !important; font-size: 0.85em; font-weight:bold; border:none; padding:8px 4px;color:" +
        window.CookieConsent.config.theme.barMainButtonTextColor +
        "; background-color:" +
        window.CookieConsent.config.theme.barMainButtonColor +
        " !important;}",
      "@media (max-width: 600px) {",
      "html #cconsent-bar .ccb__right > div { display: block;color: #FFF;width: 100%;text-align: center;}",
      "html #cconsent-bar button.btn {  display:block !important;    margin: 10px auto; width:100% }",
      "}",

      "html #cconsent-bar button.btn.necesary {    background-color: #cccccc !important;  color: #000000 !important;}",

      "html #cconsent-bar a.cookiesconfg { margin-right:15px }",
      "html #cconsent-bar a:hover, html #cconsent-bar button:hover { cursor:pointer; }",
      "#cconsent-modal { display:none; font-size:14px; line-height:18px; color:#666; width: 100vw; height: 100vh; position:fixed; left:0; top:0; right:0; bottom:0; font-family: 'Gotham' sans-serif; font-size:14px; background-color:rgba(0,0,0,0.6); z-index:9999; align-items:center; justify-content:center;}",
      "@media (max-width: 600px) { #cconsent-modal { height: 100% } }",
      "#cconsent-modal h2, #cconsent-modal h3 {color:#333}",
      "#cconsent-modal.ccm--visible {display:flex}",
      "#cconsent-modal .ccm__content { max-width:600px; min-height:500px; max-height:600px; overflow-Y:auto; background-color:#EFEFEF; }",
      "@media (max-width: 600px) { #cconsent-modal .ccm__content { max-width:100vw; height:100%; max-height:initial; }}",
      "#cconsent-modal .ccm__content > .ccm__content__heading { border-bottom:1px solid #D8D8D8; padding:35px 35px 20px; background-color:#EFEFEF; position:relative;}",
      "#cconsent-modal .ccm__content > .ccm__content__heading h2 { font-size:21px; font-weight:600; color:#333; margin:0 }",
      "#cconsent-modal .ccm__content > .ccm__content__heading .ccm__cheading__close {font-weight:600; color:#888; cursor:pointer; font-size:26px; position: absolute; right:15px; top: 15px;}",
      "#cconsent-modal h2, #cconsent-modal h3 {margin-top:0}",
      "#cconsent-modal .ccm__content > .ccm__content__body { background-color:#FFF;}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup {margin:0; border-bottom: 1px solid #D8D8D8; }",
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup .ccm__tab-head::before { position:absolute; left:35px; font-size:1.4em; font-weight: 600; color:#E56385; content:"×"; display:inline-block; margin-right: 20px;}',
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.checked-5jhk .ccm__tab-head::before {font-size:1em; content:"✔"; color:#28A834}',
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup .ccm__tab-head .ccm__tab-head__icon-wedge { transition: transform .3s ease-out; transform-origin: 16px 6px 0; position:absolute;right:25px; top:50%; transform:rotate(0deg); transform:translateY(-50%)}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup .ccm__tab-head .ccm__tab-head__icon-wedge > svg { pointer-events: none; }",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.ccm__tabgroup--open .ccm__tab-head .ccm__tab-head__icon-wedge {transform:rotate(-180deg)}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-head {color:#333; padding:17px 35px 17px 56px; margin:0}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content {padding:25px 35px; margin:0}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-head { transition: background-color .5s ease-out }",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-head:hover { background-color:#F9F9F9 }",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-head {font-weight:600; cursor:pointer; position:relative;}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup .ccm__tab-content {display:none;}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.ccm__tabgroup--open .ccm__tab-head { background-color:#F9F9F9 }",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.ccm__tabgroup--open .ccm__tab-content {display:flex;}",
      "@media (max-width: 600px) { #cconsent-modal .ccm__content > .ccm__content__body .ccm__tabgroup.ccm__tabgroup--open .ccm__tab-content {flex-direction:column} }",
      "@media (max-width: 600px) { #cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__tab-content__left { margin-bottom:20px; } }",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__tab-content__left .ccm__switch-component {display:flex; margin-right:35px; align-items:center;}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__tab-content__left .ccm__switch-component > div {font-weight:600;}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__tab-content__left .ccm__switch-group {width:40px; height:20px; margin:0 10px; position:relative;}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__tab-content__left .ccm__switch {position: absolute; top:0; right:0; display: inline-block; width: 40px; height: 20px;}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__tab-content__left .ccm__switch input {display:none;}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__tab-content__left .ccm__switch .ccm__switch__slider  {position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ccc; border-radius:10px !important; -webkit-transition: .4s; transition: .4s;}",
      '#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__tab-content__left .ccm__switch .ccm__switch__slider:before  {position: absolute; content: ""; height: 12px; width: 12px; left: 4px; bottom: 4px; background-color: white; border-radius:50%; -webkit-transition: .4s; transition: .4s;}',
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__tab-content__left .ccm__switch input:checked + .ccm__switch__slider  {background-color: #28A834;}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__tab-content__left .ccm__switch input:focus + .ccm__switch__slider  {box-shadow: 0 0 1px #28A834;}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__tab-content__left .ccm__switch input:checked + .ccm__switch__slider:before  {-webkit-transform: translateX(20px); -ms-transform: translateX(20px); transform: translateX(20px);}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content h3 {font-size:18px; margin-bottom:10px; line-height:1;}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content p {color:#444; margin-bottom:0}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__list:not(:empty) {margin-top:30px;}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__list .ccm__list__title {color:#333; font-weight:600;}",
      "#cconsent-modal .ccm__content > .ccm__content__body .ccm__tab-content .ccm__list ul { margin:15px 0; padding-left:15px }",
      "#cconsent-modal .ccm__footer { padding:35px; background-color:#EFEFEF; text-align:center; display: flex; align-items:center; justify-content:flex-end; }",
      "#cconsent-modal .ccm__footer button { line-height:normal; font-size:14px; transition: background-color .5s ease-out; background-color:" +
        window.CookieConsent.config.theme.modalMainButtonColor +
        "; color:" +
        window.CookieConsent.config.theme.modalMainButtonTextColor +
        "; border:none; padding:13px; min-width:110px; border-radius: 2px; cursor:pointer; }",
      "#cconsent-modal .ccm__footer button:hover { background-color:" +
        Utilities.lightenDarkenColor(
          window.CookieConsent.config.theme.modalMainButtonColor,
          -20
        ) +
        "; }",
      "#cconsent-modal .ccm__footer button#ccm__footer__consent-modal-submit, #cconsent-modal .ccm__footer button#ccm__footer__consent-modal-necesary, #cconsent-modal .ccm__footer button.consent-decline  {  margin-right:10px; }"
    );
  }

  buildBar() {
    return el(
      "div#cconsent-bar.ccb--hidden",
      el(
        `div.ccb__wrapper`,
        el(
          "div.ccb__center",
          el(
            "div.cc-title",
            Language.getTranslation(
              window.CookieConsent.config,
              window.CookieConsent.config.language.current,
              "barMaintitle"
            )
          ),
          el(
            "div.cc-text",
            Language.getTranslation(
              window.CookieConsent.config,
              window.CookieConsent.config.language.current,
              "barMainText"
            )
          ),
          el(
            "div.ccb__right",
            el(
              "div.ccb__button",
              ///el('a.cookiesconfg', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barLinkSetting')),
              /*el(
                "button.btn.necesary",
                Language.getTranslation(
                  window.CookieConsent.config,
                  window.CookieConsent.config.language.current,
                  "barBtnAcceptNecesary"
                )
              ),*/
              el(
                "button.btn.configure",
                Language.getTranslation(
                  window.CookieConsent.config,
                  window.CookieConsent.config.language.current,
                  "barBtnAcceptSelect"
                )
              ),
              window.CookieConsent.config.showRejectAllButton &&
              el(
                "button.btn.consent-decline",
                Language.getTranslation(
                  window.CookieConsent.config,
                  window.CookieConsent.config.language.current,
                  "barBtnRejectAll"
                )
              ),
              el(
                "button.btn.consent-give",
                Language.getTranslation(
                  window.CookieConsent.config,
                  window.CookieConsent.config.language.current,
                  "barBtnAcceptAll"
                )
              )
            )
          )
        )

        /* el('div.ccb__left',
            el('div.cc-text', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barMainText'))
          ),
          el('div.ccb__right',
            el('div.ccb__button',
              el('a.cookiesconfg', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barLinkSetting')),
              el('button.consent-give', Language.getTranslation(window.CookieConsent.config, window.CookieConsent.config.language.current, 'barBtnAcceptAll'))
            )
          )*/
      )
    );
  }

  buildModal() {
    // Cookie names list middleware
    var listCookies = function (category) {
      var list = [];

      for (let service in window.CookieConsent.config.services) {
        window.CookieConsent.config.services[service].category === category &&
          list.push(window.CookieConsent.config.services[service]);
      }

      if (list.length) {
        var listItems = [];

        for (let item in list) {
          var isLInk = list[item].link;
          listItems.push(
            el(
              "li",
              el(
                isLInk ? "a" : "span",
                isLInk
                  ? {
                      href: list[item].link ? list[item].link : "",
                      target: "_blank",
                      rel: "noopener noreferrer",
                    }
                  : {},
                Language.getTranslation(
                  list[item],
                  window.CookieConsent.config.language.current,
                  "name"
                )
              )
            )
          );
        }

        return [
          el(
            "div.ccm__list",
            el(
              "span.ccm__list__title",
              Language.getTranslation(
                window.CookieConsent.config,
                window.CookieConsent.config.language.current,
                "modalAffectedSolutions"
              )
            ),
            el("ul", listItems)
          ),
        ];
      }
    };

    function link(item) {
      console.log("link", item.language.locale);
    }

    function modalTabGroups() {
      let contentItems = [];

      let i = 0;
      for (let key in window.CookieConsent.config.categories) {
        contentItems.push(
          el(
            "dl.ccm__tabgroup" +
              "." +
              key +
              (window.CookieConsent.config.categories[key].checked
                ? ".checked-5jhk"
                : ""),
            { "data-category": key },
            el(
              "dt.ccm__tab-head",
              Language.getTranslation(
                window.CookieConsent.config.categories[key],
                window.CookieConsent.config.language.current,
                "name"
              ),
              el(
                "a.ccm__tab-head__icon-wedge",
                el(
                  document.createElementNS("http://www.w3.org/2000/svg", "svg"),
                  {
                    version: "1.2",
                    preserveAspectRatio: "none",
                    viewBox: "0 0 24 24",
                    class: "icon-wedge-svg",
                    "data-id": "e9b3c566e8c14cfea38af128759b91a3",
                    style:
                      "opacity: 1; mix-blend-mode: normal; fill: rgb(51, 51, 51); width: 32px; height: 32px;",
                  },
                  el(
                    document.createElementNS(
                      "http://www.w3.org/2000/svg",
                      "path"
                    ),
                    {
                      "xmlns:default": "http://www.w3.org/2000/svg",
                      class: "icon-wedge-angle-down",
                      d: "M17.2,9.84c0-0.09-0.04-0.18-0.1-0.24l-0.52-0.52c-0.13-0.13-0.33-0.14-0.47-0.01c0,0-0.01,0.01-0.01,0.01  l-4.1,4.1l-4.09-4.1C7.78,8.94,7.57,8.94,7.44,9.06c0,0-0.01,0.01-0.01,0.01L6.91,9.6c-0.13,0.13-0.14,0.33-0.01,0.47  c0,0,0.01,0.01,0.01,0.01l4.85,4.85c0.13,0.13,0.33,0.14,0.47,0.01c0,0,0.01-0.01,0.01-0.01l4.85-4.85c0.06-0.06,0.1-0.15,0.1-0.24  l0,0H17.2z",
                      style: "fill: rgb(51, 51, 51);",
                    }
                  )
                )
              )
            ),
            el(
              "dd.ccm__tab-content",
              el(
                "div.ccm__tab-content__left",
                !window.CookieConsent.config.categories[key].needed &&
                  el(
                    "div.ccm__switch-component",
                    el(
                      "div.status-off",
                      Language.getTranslation(
                        window.CookieConsent.config,
                        window.CookieConsent.config.language.current,
                        "off"
                      )
                    ),
                    el(
                      "div.ccm__switch-group",
                      el(
                        "label.ccm__switch",
                        el("input.category-onoff", {
                          type: "checkbox",
                          "data-category": key,
                          checked:
                            window.CookieConsent.config.categories[key].checked,
                        }),
                        el("span.ccm__switch__slider")
                      )
                    ),
                    el(
                      "div.status-on",
                      Language.getTranslation(
                        window.CookieConsent.config,
                        window.CookieConsent.config.language.current,
                        "on"
                      )
                    )
                  )
              ),
              el(
                "div._right",
                el(
                  "h3",
                  Language.getTranslation(
                    window.CookieConsent.config.categories[key],
                    window.CookieConsent.config.language.current,
                    "name"
                  )
                ),
                el(
                  "p",
                  Language.getTranslation(
                    window.CookieConsent.config.categories[key],
                    window.CookieConsent.config.language.current,
                    "description"
                  )
                ),
                el("div.ccm__list", listCookies(key))
              )
            )
          )
        );

        i++;
      }

      return contentItems;
    }
    let mount = el(
      "div#cconsent-modal",
      el(
        "div.ccm__content",
        el(
          "div.ccm__content__heading",
          el(
            "h2",
            Language.getTranslation(
              window.CookieConsent.config,
              window.CookieConsent.config.language.current,
              "modalMainTitle"
            )
          ),
          el(
            "p",
            Language.getTranslation(
              window.CookieConsent.config,
              window.CookieConsent.config.language.current,
              "modalMainText"
            ),
            window.CookieConsent.config.modalMainTextMoreLink
              ? el(
                  "a",
                  {
                    href: window.CookieConsent.config.modalMainTextMoreLink,
                    target: "_blank",
                    rel: "noopener noreferrer",
                  },
                  Language.getTranslation(
                    window.CookieConsent.config,
                    window.CookieConsent.config.language.current,
                    "learnMore"
                  )
                )
              : null
          ),
          el("div.ccm__cheading__close", "×")
        ),
        el("div.ccm__content__body", el("div.ccm__tabs", modalTabGroups())),
        el(
          "div.ccm__footer",
          /*el(
            "button#ccm__footer__consent-modal-necesary.necesary",
            Language.getTranslation(
              window.CookieConsent.config,
              window.CookieConsent.config.language.current,
              "barBtnAcceptNecesary"
            )
          ),*/
          el(
            "button#ccm__footer__consent-modal-submit",
            Language.getTranslation(
              window.CookieConsent.config,
              window.CookieConsent.config.language.current,
              "modalBtnSave"
            )
          ),
          window.CookieConsent.config.showRejectAllButton &&
            el(
              "button.consent-decline",
              Language.getTranslation(
                window.CookieConsent.config,
                window.CookieConsent.config.language.current,
                "modalBtnRejectAll"
              )
            ),
          el(
            "button.consent-give",
            Language.getTranslation(
              window.CookieConsent.config,
              window.CookieConsent.config.language.current,
              "modalBtnAcceptAll"
            )
          )
        )
      )
    );

    return mount;
  }

  modalRedrawIcons() {
    var tabGroups = this.elements["modal"].querySelectorAll(".ccm__tabgroup");

    for (let tabGroup of tabGroups) {
      if (
        window.CookieConsent.config.categories[tabGroup.dataset.category]
          .checked
      ) {
        if (!tabGroup.classList.contains("checked-5jhk")) {
          tabGroup.classList.add("checked-5jhk");
          tabGroup.querySelector("input.category-onoff").checked = true;
        }
      } else {
        if (tabGroup.classList.contains("checked-5jhk"))
          tabGroup.classList.remove("checked-5jhk");
        tabGroup.querySelector("input.category-onoff").checked = false;
      }
    }
  }

  render(name, elem, callback) {
    if (typeof callback === "undefined") callback = function () {};
    if (typeof this.elements[name] !== "undefined") {
      this.elements[name].parentNode.replaceChild(elem, this.elements[name]);
      this.elements[name] = elem;
      callback(elem);
      return elem;
    } else {
      var insertedElem = mount(document.body, elem);
      if (insertedElem) {
        this.elements[name] = insertedElem;
      }
      callback(insertedElem);
      return insertedElem;
    }
  }

  buildInterface(callback) {
    if (typeof callback === "undefined") callback = function () {};
    var that = this;

    Utilities.ready(function () {
      var WebFontConfig = {
        google: {
          families: ["Roboto:300,400,700"],
        },
      };
      /*
      (function (d) {
        var wf = d.createElement("script"),
          s = d.scripts[0];
        wf.src =
          "https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js";
        wf.async = true;
        s.parentNode.insertBefore(wf, s);
      })(document);
      */
      that.render("style", that.buildStyle());

      that.render("bar", that.buildBar(), (bar) => {
        // Show the bar after a while
        if (!window.CookieConsent.config.cookieExists) {
          setTimeout(() => {
            bar.classList.remove("ccb--hidden");
          }, window.CookieConsent.config.barTimeout);
        }
      });

      that.render("modal", that.buildModal());

      callback();
    });
  }

  addEventListeners(elements) {
    // If you click Accept all cookies
    var buttonConsentGive = document.querySelectorAll(".consent-give");

    for (let button of buttonConsentGive) {
      button.addEventListener("click", () => {
        // console.log("Accept all cookies");
        // We set config to full consent
        for (let key in window.CookieConsent.config.categories) {
          window.CookieConsent.config.categories[key].wanted =
            window.CookieConsent.config.categories[key].checked = true;
        }

        this.writeBufferToDOM();

        this.buildCookie((cookie) => {
          this.setCookie(cookie);
        });

        this.elements["bar"].classList.add("ccb--hidden");
        this.elements["modal"].classList.remove("ccm--visible");

        this.modalRedrawIcons();
      });
    }

        // If you click Reject all cookies
        var buttonConsentDecline = document.querySelectorAll(".consent-decline");

        for (let button of buttonConsentDecline) {
          button.addEventListener("click", () => {
            var buttonSettings = document.querySelector(".ccb__edit");
            var buttonConsentGive = document.querySelector(".consent-give");
    
            // We set config to full consent only in is needed
            for (let key in window.CookieConsent.config.categories) {
              window.CookieConsent.config.categories[key].wanted =
                window.CookieConsent.config.categories[key].checked =
                  window.CookieConsent.config.categories[key].needed;
            }
    
            this.writeBufferToDOM();
    
            this.buildCookie((cookie) => {
              this.setCookie(cookie);
            });
    
            this.elements["bar"].classList.add("ccb--hidden");
            this.elements["bar"].setAttribute("aria-hidden", "true");
            this.elements["bar"].setAttribute("tabindex", "-1");
            this.elements["modal"].classList.remove("ccm--visible");
            this.elements["modal"].setAttribute("aria-hidden", "true");
            this.elements["modal"].setAttribute("tabindex", "-1");
            button.setAttribute("tabindex", "-1");
            button.setAttribute("aria-hidden", "true");
            buttonSettings.setAttribute("tabindex", "-1");
            buttonSettings.setAttribute("aria-hidden", "true");
            buttonConsentGive.setAttribute("tabindex", "-1");
            buttonConsentGive.setAttribute("aria-hidden", "true");
            focusTarget.focus();
            modalOpen = false;
    
            this.modalRedrawIcons();
          });
        }

    // If you click Cookie settings and open modal
    Array.prototype.forEach.call(
      document.getElementsByClassName("cookiesconfg"),
      (edit) => {
        edit.addEventListener("click", () => {
          this.elements["modal"].classList.add("ccm--visible");
        });
      }
    );

    Array.prototype.forEach.call(
      document.getElementsByClassName("configure"),
      (edit) => {
        edit.addEventListener("click", () => {
          this.elements["modal"].classList.add("ccm--visible");
        });
      }
    );

    // button#ccm__footer__consent-modal-necesary

    Array.prototype.forEach.call(
      document.getElementsByClassName("necesary"),
      (edit) => {
        edit.addEventListener("click", () => {
          //this.elements["modal"].classList.add("ccm--visible");

          // console.log("Accept onlye necesary cookies");
          // We set config to full consent
          for (let key in window.CookieConsent.config.categories) {
            // window.CookieConsent.config.categories[key].wanted = window.CookieConsent.config.categories[key].checked = true;
            // console.log(key, window.CookieConsent.config.categories[key])
            if (key === "necessary") {
              window.CookieConsent.config.categories[key].wanted =
                window.CookieConsent.config.categories[key].checked = true;
            } else {
              window.CookieConsent.config.categories[key].wanted =
                window.CookieConsent.config.categories[key].checked = false;
            }
          }

          this.writeBufferToDOM();

          this.buildCookie((cookie) => {
            this.setCookie(cookie);
          });

          this.elements["bar"].classList.add("ccb--hidden");
          this.elements["modal"].classList.remove("ccm--visible");

          this.modalRedrawIcons();
        });
      }
    );

    // If you click trough the tabs on Cookie settings
    // If you click on/off switch
    this.elements["modal"]
      .querySelector(".ccm__tabs")
      .addEventListener("click", (event) => {
        // If you click trough the tabs on Cookie settings
        if (
          event.target.classList.contains("ccm__tab-head") ||
          event.target.classList.contains("ccm__tab-head__icon-wedge")
        ) {
          function getDlParent(eventTarget) {
            var parent = eventTarget.parentNode;
            if (parent.nodeName !== "DL") {
              return getDlParent(parent);
            } else {
              return parent;
            }
          }

          var parentDl = getDlParent(event.target);

          if (parentDl.classList.contains("ccm__tabgroup--open")) {
            parentDl.classList.remove("ccm__tabgroup--open");
          } else {
            parentDl.classList.add("ccm__tabgroup--open");
          }
        }

        // If you click on/off switch
        if (event.target.classList.contains("category-onoff")) {
          window.CookieConsent.config.categories[
            event.target.dataset.category
          ].wanted = window.CookieConsent.config.categories[
            event.target.dataset.category
          ].checked = event.target.checked === true ? true : false;

          var dt = document.querySelector(
            ".ccm__tabgroup." + event.target.dataset.category
          );
          if (
            event.target.checked === false &&
            dt.classList.contains("checked-5jhk")
          ) {
            dt.classList.remove("checked-5jhk");
          } else {
            dt.classList.add("checked-5jhk");
          }
        }
      });

    // If you click close on open modal
    this.elements["modal"]
      .querySelector(".ccm__cheading__close")
      .addEventListener("click", (event) => {
        this.elements["modal"].classList.remove("ccm--visible");
      });

    // If you click submit on cookie settings
    document
      .getElementById("ccm__footer__consent-modal-submit")
      .addEventListener("click", () => {
        let switchElements =
          this.elements["modal"].querySelectorAll(".ccm__switch input");

        Array.prototype.forEach.call(switchElements, (switchElement) => {
          window.CookieConsent.config.categories[
            switchElement.dataset.category
          ].wanted = switchElement.checked;
        });

        this.buildCookie((cookie) => {
          this.setCookie(cookie, () => {
            this.elements["modal"].classList.remove("ccm--visible");
            this.elements["bar"].classList.add("ccb--hidden");
          });
        });

        this.writeBufferToDOM();
      });
  }

  writeBufferToDOM() {
    for (let action of window.CookieConsent.buffer.appendChild) {
      if (
        window.CookieConsent.config.categories[action.category].wanted === true
      ) {
        Node.prototype.appendChild.apply(action.this, action.arguments);
      }
    }

    for (let action of window.CookieConsent.buffer.insertBefore) {
      if (
        window.CookieConsent.config.categories[action.category].wanted === true
      ) {
        action.arguments[1] =
          action.arguments[0].parentNode === null
            ? action.this.lastChild
            : action.arguments[1];
        Node.prototype.insertBefore.apply(action.this, action.arguments);
      }
    }
  }

  buildCookie(callback) {
    let cookie = {
      version: window.CookieConsent.config.cookieVersion,
      categories: {},
      services: [],
    };

    for (let key in window.CookieConsent.config.categories) {
      cookie.categories[key] = {
        wanted: window.CookieConsent.config.categories[key].wanted,
      };
    }

    cookie.services = Utilities.listGlobalServices();

    if (callback) callback(cookie);
    return cookie;
  }

  setCookie(cookie, callback) {
    const expires_in = new Date(
      Date.now() + 365 * 24 * 60 * 60 * 1000
    ).toUTCString();

    document.cookie = `cconsent=${JSON.stringify(
      cookie
    )}; expires=${expires_in}; path=/;`;
    if (callback) callback();
  }
}
