import Utilities from "./Utilities";

export default class RemoveCookies {
  init() {
    this.removeUnwantedCookies();

    this.listenCookieChange(({ oldValue, newValue }) => {
      // console.log(`Cookie changed from "${oldValue}" to "${newValue}"`);
      this.removeUnwantedCookies();
    }, 1000);
  }

  removeUnwantedCookies() {
    let cookieList = [];
    let config = window.CookieConsent.config;

    document.cookie.split(";").map(function (a) {
      cookieList.push(a.split("=")[0].replace(/(^\s*)|(\s*&)/, ""));
    });
    // console.log('removeUnwantedCookies', cookieList)

    for (let service in config.services) {
      if (Utilities.objectType(config.services[service].cookies) === "Array") {
        // Remove cookies if they are not wanted by user

        // console.log('wanted', config.categories[config.services[service].category].wanted)
        if (!config.categories[config.services[service].category].wanted) {
          for (let i in config.services[service].cookies) {
            let type = Utilities.objectType(
              config.services[service].cookies[i].name
            );
            if (type === "String") {
              if (
                cookieList.indexOf(config.services[service].cookies[i].name) >
                -1
              ) {
                this.removeCookie(config.services[service].cookies[i]);
              }
            } else if (type === "RegExp") {
              // Searching cookie list for cookies matching specified RegExp
              let cookieDef = config.services[service].cookies[i];
              for (let c in cookieList) {
                // console.log('Para borrar', cookieList[c], cookieDef.name, 'si/no',cookieList[c].match(cookieDef.name))
                if (cookieList[c].match(cookieDef.name)) {
                  // console.log('Borro', cookieList[c], cookieDef.name, cookieDef.domain)
                  this.removeCookie({
                    name: cookieList[c],
                    domain:
                      Utilities.objectType(cookieDef.domain) === "String"
                        ? cookieDef.domain
                        : "/",
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  removeCookie(cookie) {
    // Removing cookies from domain and .domain
    let domain =
      Utilities.objectType(cookie.domain) === "String"
        ? `domain=${cookie.domain}`
        : "";
    // console.log(cookie.name, domain )
    // document.cookie = `${cookie.name}=; expires=Thu, 01 Jan 1980 00:00:00 UTC; ${domain} path=/;`;
    var time = new Date(0).toUTCString();
    // console.log('R:', cookie.name + '=; path=/; domain=' + domain + '; expires=' + new Date(0).toUTCString());
    document.cookie =
      cookie.name +
      "=; path=/; domain=" +
      domain +
      "; expires=" +
      new Date(0).toUTCString();
    setTimeout(() => {
      // console.log(' ahora vuelvo a borrar ', cookie.name)
      document.cookie =
        cookie.name +
        "=; path=/; domain=.gestair.com; expires=" +
        new Date(0).toUTCString();
    }, 1000);
  }

  listenCookieChange(callback, interval = 1000) {
    let lastCookie = document.cookie;
    setInterval(() => {
      let cookie = document.cookie;
      if (cookie !== lastCookie) {
        try {
          callback({ oldValue: lastCookie, newValue: cookie });
        } finally {
          lastCookie = cookie;
        }
      }
    }, interval);
  }
}
